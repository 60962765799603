<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="出库单id" >
                <el-input v-model="search.chuKuDanId" size="small" />
            </x-search-item>
            <x-search-item label="出库类型" >
                <el-select v-model="search.chuKuLX" placeholder="请选择出库类型" size="small">
                    <el-option :label="ckLX.DBCK.name" :value="ckLX.DBCK.key"></el-option>
                    <el-option v-if="!showOption" :label="ckLX.SQDBCK.name" :value="ckLX.SQDBCK.key"></el-option>
                    <el-option :label="ckLX.THZGHSCK.name" :value="ckLX.THZGHSCK.key"></el-option>
                    <el-option v-if="showOption" :label="ckLX.THZSHCK.name" :value="ckLX.THZSHCK.key"></el-option>
                    <el-option :label="ckLX.PKCK.name" :value="ckLX.PKCK.key"></el-option>
                    <el-option :label="ckLX.QTCK.name" :value="ckLX.QTCK.key"></el-option>
                    <el-option v-if="showOption" :label="ckLX.XSCK.name" :value="ckLX.XSCK.key"></el-option>
                    <el-option v-if="showOption" :label="ckLX.ZPCK.name" :value="ckLX.ZPCK.key"></el-option>
                </el-select>
            </x-search-item>
            <x-search-item label="商品名称" >
                <el-input v-model="search.shangPinMC" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
<!--        <div class="x-page-btn">-->
<!--&lt;!&ndash;            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>&ndash;&gt;-->
<!--            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">-->
<!--                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>-->
<!--            </el-popconfirm>-->
<!--        </div>-->
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection" width="40" fixed/>-->
            <el-table-column width="50" label="序号" type="index" />
<!--            <el-table-column width="180" prop="chuKuDanId" label="出库单id" />-->
            <el-table-column width="180" prop="shangPinMC" label="商品名称" />
<!--            <el-table-column width="180" prop="shangPinHuoHao" label="商品货号" />
            <el-table-column width="180" prop="shangPinTiaoMa" label="商品条码" />-->
            <el-table-column prop="shangPinHuoHao" label="货号" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{scope.row.shiFuDuoGuiGe=="true"?scope.row.guiGeHuoHao:scope.row.shangPinHuoHao}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="shangPinTiaoMa" label="条码" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{scope.row.shiFuDuoGuiGe=="true"?scope.row.guiGeTiaoMa:scope.row.shangPinTiaoMa}}</span>
                </template>
            </el-table-column>
            <el-table-column width="100" prop="chengBenJia" label="成本价">
                <template slot-scope="scope">
                    <span>{{scope.row.shiFuDuoGuiGe=="true"?scope.row.guiGeShangPinChengBenJia:scope.row.chengBenJia}}</span>
                </template>
            </el-table-column>
            <el-table-column  prop="chuKuLX" label="出库类型" >
                <x-dict-show slot-scope="{row}" :code="row.chuKuLX" dictType="T_CHU_KU_LX" />
            </el-table-column>
            <el-table-column  prop="chuKuSJ" label="出库时间" show-overflow-tooltip/>
            <el-table-column  prop="chuKuJia" label="出库价" />
            <el-table-column  prop="kuCunSL" label="库存数量" />
            <el-table-column  prop="chuKuSL" label="出库数量" />
            <el-table-column  prop="chuKuJinE" label="出库金额" />
            <el-table-column  prop="shangPinDW" label="商品单位" />
<!--            <el-table-column  prop="shengChanRQ" label="生产日期" />
            <el-table-column  prop="baoZhiQiTian" label="保质期（天）" />
            &lt;!&ndash;            <el-table-column width="180" prop="chuKuCangKuId" label="出库仓库id" />&ndash;&gt;
            <el-table-column  prop="chuKuCangKu" label="出库仓库" />
            <el-table-column  prop="shiFuDuoGuiGe" label="是否多规格" >
                <x-dict-show slot-scope="{row}" :code="row.shiFuDuoGuiGe" dictType="T_YES_OR_NO" />
            </el-table-column>-->
            <el-table-column  prop="beiZhu" label="备注" />
<!--            <el-table-column width="180" prop="shiFuShanChu" label="是否删除" />-->
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
<!--                    <el-button type="primary" size="mini" round @click="handleUpdate(row.id)">编辑</el-button>-->
<!--                    <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">-->
<!--                        <el-button type="danger" size="mini" round slot="reference">删除</el-button>-->
<!--                    </el-popconfirm>-->
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/kcgl/ChuKuJiLu";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/kcgl/ckjl/ChuKuJiLuEdit";
    import Detail from "@/view/kcgl/ckjl/ChuKuJiLuDetail";
    import {TYPE_CHU_KU_LX,DICT_TYPE_USER_ORGAN_LX} from "@/util/constant"
    export default {
        name: "ChuKuJiLuList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                ckLX: TYPE_CHU_KU_LX,
                search: {
                    chuKuDanId: '',
                    chuKuLX: '',
                    shangPinMC: '',
                    shangPinId: '',
                }
            }
        },
        computed: {
            showOption() {
                // 门店为true
                return this.$store.getters.user.userOrganLx===DICT_TYPE_USER_ORGAN_LX.MD.key;
            },
        }
    }
</script>

<style scoped>
</style>
